import { Routes } from '@constants'
import React from 'react'
import Button from '@atoms/Button'

function GoogleSignIn() {
  return (
    <a href={Routes.APP.USER.GOOGLE_LOGIN}>
      <Button
        size="large"
        variant="contained"
        color="google"
        className="btn-login relative"
      >
        <span className="btn-google-icon">
          <img src="/google.svg" alt="google logo" className="btn-google-img" />
        </span>
        Start free with Google
      </Button>
    </a>
  )
}

export default GoogleSignIn
